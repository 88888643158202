.extra_page__css h1{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.extra_page__css h2{
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.extra_page__css h3{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.extra_page__css h4{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.extra_page__css h5{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.extra_page__css h6{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.extra_page__css a{
    color: #00813c;
    text-decoration: underline;
}
