@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap");

@import "./icons/style.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.dropdown:hover .dropdown-menu {
    display: block;
  }
