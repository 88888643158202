.orderBtnInactive{
  position: absolute;
  margin: auto;
  right: 60px;
  bottom: 20px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  background: #090909ac;
  backdrop-filter: blur(3px);
  color: #fff;
  width: 100px;
  height: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  transform: translate(-50%, -50%) scale(0.00001);
  transition: transform 0.4s, top 0.4s;
};
.orderBtnInactive:hover{
  background: #09090991;
  backdrop-filter: blur(1px);
  transition: .4s;
}
.orderBtnActive{
  visibility: visible;
  transform: translate(40%, -50%) scale(1);
}
.btnCircle {
  position: absolute;
  width: 105px;
  height: 105px;
  border: 4px solid rgb(219, 218, 218);
  border-radius: 50%;
  transition: all .3s;
}

.circleOne {
  clip: rect(0,120px,120px,0);
  transform: rotate(90deg);
}
.circleTwo {
  clip: rect(0,62px,110px,0);
  transform: rotate(100deg);
}
